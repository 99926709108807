<template>
    <div class="dashboard-container">
        <div class="navbar">
            <router-link to="/discounts" :class="$route.path == '/discounts' && 'active'">Businesses</router-link>
            <router-link to="/cities" :class="$route.path == '/cities' && 'active'">Cities</router-link>
            <router-link to="/users" :class="$route.path == '/users' && 'active'">Users</router-link>
            <a href="#" style="color: #aaa;">Withdrawals</a>
            <a href="javascript:;" v-on:click="logoutUser">Logout</a>
        </div>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'NavigationMenu',
    data() {
        return {
            //userName: ''
        }
    },
    computed: {
        ...mapStores(useAuthStore)
    },
    methods: {
        logoutUser() {
            this.authStore.logout()
        }
    }
}
</script>

<style lang="scss" scoped>
.navbar {
	padding: 5px 30px;
	background-color: $navbar-background-color;
	display: flex;
	align-items: center;
    height: $nav-height;
    box-sizing: border-box;
    width: 100%;
    gap: 30px;

    .logo {
        padding: 10px;
    }

    a {
        text-decoration: none;
        color: #fff;
        line-height: 26px;

        &.active {
            border-bottom: 1px solid #fff;
        }
    }

    span.user-name {
		font-size: $font-size-small;
        font-weight: $font-weight-bold;
        position: relative;
        color: $white-color;
        padding: 12px 34px 12px 12px;

        &::before {
            position: absolute;
            content: '';
            background-image: url('~@/assets/images/icon-user.svg');
            width: 18px;
            height: 20px;
            right: 10px;
            top: 12px;
        }
	}
}
</style>
