import CitiesIndex from '@/views/CitiesIndex.vue';
import CitiesCreate from '@/views/CitiesCreate.vue';
import CitiesEdit from '@/views/CitiesEdit.vue';

const routes = [
    {
        path: '/cities',
		name: 'Cities',
		component: CitiesIndex,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/cities/create',
		name: 'CitiesCreate',
		component: CitiesCreate,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/cities/:id/edit',
		name: 'CitiesEdit',
		component: CitiesEdit,
        meta: { layout: 'DashboardLayout' }
    }
];

export default routes
