<template>
    <Teleport to="body">
        <div class="overlay-modal">
            <div class="overlay-modal__container">
                <div class="overlay-modal__header">
                    <span>{{ title }}</span>
                    <a href="javascript:;" v-on:click="modalClosed" class="close-icon">
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_702_5890)">
                            <path d="M11.3653 9.85349L18.445 2.77454C18.8845 2.33507 18.8845 1.62255 18.445 1.18312C18.0056 0.743648 17.2931 0.743648 16.8536 1.18312L9.77463 8.2628L2.69568 1.18312C2.25621 0.743648 1.5437 0.743648 1.10426 1.18312C0.664826 1.62259 0.664791 2.33511 1.10426 2.77454L8.18395 9.85349L1.10426 16.9325C0.664791 17.3719 0.664791 18.0845 1.10426 18.5239C1.54373 18.9633 2.25625 18.9634 2.69568 18.5239L9.77463 11.4442L16.8536 18.5239C17.2931 18.9634 18.0056 18.9634 18.445 18.5239C18.8845 18.0844 18.8845 17.3719 18.445 16.9325L11.3653 9.85349Z" fill="#313131"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_702_5890">
                            <rect width="18" height="18" fill="white" transform="translate(0.774414 0.853516)"/>
                            </clipPath>
                            </defs>
                        </svg>
                    </a>
                </div>
                <div class="overlay-modal__content">
                    <slot />
                </div>
                <div class="overlay-modal__footer">
                    <button class="btn btn-primary" v-if="submitText && submitText.length > 0"
                            @click="modalSubmitted">{{ submitText }}</button>
                    <button class="btn btn-cancel"
                            @click="modalClosed">{{ myCloseText }}</button>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script>
export default {
    name: 'OverlayModal',
    props: ['title', 'submitText', 'closeText'],
    emits: ['modalSubmitted', 'modalClosed'],
    data() {
        return {

        }
    },
    computed: {
        myCloseText() {
            return this.closeText ? this.closeText : this.$t('data_table.modal_default_cancel_text');
        }
    },
    methods: {
        modalSubmitted() {
            this.$emit('modalSubmitted')
        },
        modalClosed() {
            //Close modal logic
            this.$emit('modalClosed')
        }
    }
}
</script>

<style lang="scss" scoped>
a.close-icon, .btn-cancel {
    float: right;
}
.btn.btn-cancel {
    background: #fff;
    border: 1px solid #e8e8e8;
}
.btn {
    background-color: $brand-color;
    color: $text-color;
    font-size: $font-size-small;
    padding: 7px 20px;
    border-radius: 10px;
    font-weight: $font-weight-bold;
    text-decoration: none;
    text-transform: capitalize;
    border-width: 1px;
    border-style: solid;
    border-color: $brand-color;
    display: inline-block;
    margin-top: 5px;
}
.overlay-modal {
    position:fixed;
    width: 100%;
    height: 100%;
    top:0 ;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;

    &__container {
        background: #fff;
        margin: 8% auto 20px;
        width: 90%;
        max-width: 500px;
        border-radius: 10px;

        & > div {
            padding: 20px 25px;
        }
    }

    &__header {
        border-bottom: 1px solid #eee;
    }
    &__content {
        height: auto;
        min-height: 100px;
        line-height: 28px;
    }
    &__footer {
        border-top: 1px solid #eee;
    }
}
</style>
