<template>
    <div id="discounts-index-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">Businesses</h1>

                <div class="table-header">
                    <div class="table-header__tabs"></div>
                    <div class="table-header__controls">
                        <div class="table-header__controls__search">

                        </div>
                        <a href="/discounts/create" class="table-header__controls__btn">Create</a>
                    </div>
                </div>

                <NewDataTable v-if="load" class="discounts-data-table"
                        :dataUrl="dataUrl"
                        :displayId="true"
                        :key="tableRefreshKey"
                        :dataFormatter="formatData"
                        :filters="filtersData"
                        @actionTriggered="actionTriggered">

                    <template v-slot:empty>
                        No records found
                    </template>
                </NewDataTable>

            </section>
        </div>
    </div>
</template>

<script>
import NewDataTable from '@/components/global/NewDataTable.vue'

const endpoint = process.env.VUE_APP_API_BASE + 'admin/discounts'
const publicUploadsRoot = process.env.VUE_APP_API_BASE + 'discounts/image/'

import { mapStores } from 'pinia'
import { useMiscStore } from '@/stores/misc.js';

export default {
    name: 'DiscountsIndex',
    components: {
        NewDataTable
    },
    data: function() {
        return {
            load: false,
            dataUrl: endpoint,
            tableRefreshKey: null,
            filtersData: {}
        }
    },
    computed: {
        ...mapStores(useMiscStore)
    },
    created() {
        this.filtersData = {
            'city': {
                'type': 'select',
                'label': 'City',
                'key': 'city',
                'values': this.miscStore.getCitiesDictionary()
            },
            'level': {
                'type': 'select',
                'label': 'Category',
                'key': 'category',
                'values': this.miscStore.getLevelsDictionary()
            },
            'featured': {
                'type': 'select',
                'label': 'Featured',
                'key': 'featured',
                'values': {'true': 'yes', 'false': 'no'}
            }
        }
        this.tableRefreshKey = Date.now()
        this.load = true
    },
    methods: {
        formatData(data) {
            const newData = []
            for (let obj of data) {
                const newObj = {}
                for (let key in obj) {
                    const ignore = ['geoLocation', 'social', '__v']
                    if (ignore.includes(key)) {
                        continue
                    }
                    else if (key == 'city') {
                        //@TODO - handle
                        newObj[key] = {
                            'label': key,
                            'value': obj[key].name,
                            'display_type': 'text'
                        }
                    }
                    else if (key == 'image') {
                        newObj[key] = {
                            'label': key,
                            'value': publicUploadsRoot + obj[key],
                            'display_type': 'image'
                        }
                    }
                    else {
                        newObj[key] = {
                            'label': key,
                            'value': obj[key],
                            'display_type': 'text'
                        }
                    }
                }

                const actions = {
                    'edit': `<i class="edit-action" title="Edit">Edit</i>`,
                    'delete': `<i class="delete-action" title="Delete" style="color: red;">Delete</i>`
                }
                newObj['actions'] = {
                    'label': '',
                    'value': actions,
                    'display_type': 'actions'
                }

                const newObjOrdered = Object.assign({'image': ''}, newObj)
                newData.push(newObjOrdered)
            }
            return newData
        },
        actionTriggered(actionKey, identifier) {
            if (!identifier) {
                alert('Error occurred #89')
            }
            else if (actionKey == 'edit') {
                this.$router.push('/discounts/' + identifier + '/edit')
            }
            else if (actionKey == 'delete') {
                this.deleteForm(identifier)
            }
        },
        deleteForm(identifier) {
            if (confirm('Are you sure you want to delete this entity?')) {
                this.axios.delete(endpoint + '/' + identifier)
                    .then(response => {
                        if (response.data.result == 'success') {
                            alert('success')
                            this.tableRefreshKey = Date.now()
                        }
                        else {
                            alert(response.data.message)
                        }
                    })
                    .catch(e => {
                        alert(e.message)
                        console.log(e)
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
