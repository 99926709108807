import UsersIndex from '@/views/UsersIndex.vue';

const routes = [
    {
        path: '/users',
		name: 'Users',
		component: UsersIndex,
        meta: { layout: 'DashboardLayout' }
    }
];

export default routes
