<template>
    <div class="form-holder">
        <form method="post" action="" v-on:submit="submitForm">
            <div class="form-group">
                <label>Name: *</label>
                <input type="text" v-model="finalData.name" required />
            </div>
            <div class="form-group">
                <label>city_code ("system name - english letters, numbers and _"): *</label>
                <input type="text" v-model="finalData.code" placeholder="system_name_like_this_2" required pattern="[a-z0-9_]+"
                        :disabled="isEdit"/>
            </div>
            <div class="form-group">
                <label>Country code: *</label>
                <input type="text" name="address" disabled value="ro" />
            </div>
            <div class="form-group">
                <button type="submit">{{ submitText || 'Submit' }}</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: 'CitiesForm',
    props: ['formData', 'submitText', 'isEdit'],
    emits: ['formSubmitted'],
    data: function() {
        return {
            finalData: {}
        }
    },
    created() {
        this.finalData = JSON.parse(JSON.stringify(this.formData))
    },
    methods: {
        submitForm(e) {
            e.preventDefault()
            this.$emit('formSubmitted' , this.finalData)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
