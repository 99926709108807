<template>
    <div id="cities-create-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">Add City</h1>
                <CitiesForm :formData="formData" submitText="Create" @formSubmitted="formSubmitted" />
            </section>
        </div>
    </div>
</template>

<script>
import CitiesForm from '@/components/forms/CitiesForm.vue'
const endpoint = process.env.VUE_APP_API_BASE + 'admin/cities'

import { mapStores } from 'pinia'
import { useMiscStore } from '@/stores/misc.js';

export default {
    name: 'CitiesCreate',
    components: {
        CitiesForm
    },
    data: function() {
        return {
            formData: {
                name: '',
                code: ''
            }
        }
    },
    computed: {
        ...mapStores(useMiscStore)
    },
    methods: {
        formSubmitted(finalData) {
            this.axios.post(endpoint + '/create', finalData)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.miscStore.refreshData()
                        alert('success')
                        this.$router.push('/cities')
                    }
                    else {
                        alert(response.data.message)
                    }
                }).catch(error => {
                    alert('Error occurred')
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
