import AuthLogin from '@/views/AuthLogin.vue';

const routes = [
    {
        path: '/auth/login',
		name: 'AuthLogin',
		component: AuthLogin,
        meta: { layout: 'AuthLayout' }
    }
];

export default routes
