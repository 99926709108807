import DiscountsIndex from '@/views/DiscountsIndex.vue';
import DiscountsCreate from '@/views/DiscountsCreate.vue';
import DiscountsEdit from '@/views/DiscountsEdit.vue';

const routes = [
    {
        path: '/discounts',
		name: 'Discounts',
		component: DiscountsIndex,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/discounts/create',
		name: 'DiscountsCreate',
		component: DiscountsCreate,
        meta: { layout: 'DashboardLayout' }
    },
    {
        path: '/discounts/:id/edit',
		name: 'DiscountsEdit',
		component: DiscountsEdit,
        meta: { layout: 'DashboardLayout' }
    }
];

export default routes
