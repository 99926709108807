<template>
    <div class="login-component auth-component">
        <div class="auth-container">

            <div class="auth-container__title">
                <h3>Login</h3>
            </div>

            <div class="auth-container__content">
                <form v-on:submit="login">
                    <div class="form-group">
                        <label>Phone</label>
                        <input type="phone" name="phone" v-model="phone" required="required" class="form-control" />
                    </div>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary form-control">Continue</button>
                    </div>
                </form>
            </div>
        </div>

        <OverlayModal v-if="openModal"
                title="Verification"
                submitText="Submit"
                closeText="Close"

                @modalClosed="openModal = false"
                @modalSubmitted="submitCode">

            <div class="dashboard-view">
                <div class="form-group">
                    <label>Code:</label><br />
                    <input type="text" v-model="code" />
                </div>
            </div>
        </OverlayModal>
    </div>
</template>

<script>
import OverlayModal from '@/components/global/OverlayModal.vue'

const authEndpoint = process.env.VUE_APP_API_BASE + 'admin/auth';

import { mapStores } from 'pinia'
import { useAuthStore } from '@/stores/auth.js';

export default {
    name: 'AuthLogin',
    components: {
        OverlayModal
    },
    data: function() {
        return {
            phone: '',
            code: '',
            openModal: false
        }
    },
    computed: {
        formData() {
            return {
                'phone': this.phone
            }
        },
        ...mapStores(useAuthStore)
    },
    methods: {
        login(e) {
            e.preventDefault()

            this.axios.post(authEndpoint + '/login', this.formData)
                .then(response => {
                    if (response.data['result'] == 'success') {
                        //this.authStore.setAuthData(response.data['user'], response.data['token'], response.data['refresh_token'])
                        this.code = ''
                        this.openModal = true
                    }
                    else if (response.data['result'] == 'error') {
                        alert(response.data['message']);
                    }
                }).catch(error => {
                    console.log(error)
                    alert('Error occurred. #002')
                })

        },
        submitCode() {
            const thisFormData = {
                phone: this.phone,
                code: this.code
            }
            this.axios.post(authEndpoint + '/login/verify', thisFormData)
                .then(response => {
                    if (response.data['result'] == 'success') {
                        this.authStore.setAuthData(response.data['user'], response.data['token'], response.data['refresh_token'])
                        this.$router.push('/discounts')
                    }
                    else if (response.data['result'] == 'error') {
                        alert(response.data['message']);
                    }
                }).catch(error => {
                    console.log(error)
                    alert('Error occurred. #003')
                })
        }
    }
}
</script>

<style lang="scss">
</style>
