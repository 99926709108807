import { createApp, markRaw } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import router from './router'

import axios from 'axios'
import VueAxios from 'vue-axios'

import { createPinia } from 'pinia'
const pinia = createPinia()
pinia.use(({ store }) => {
	store.router = markRaw(router)
})

//Redirec to login on protected pages for guests
import { guestRedirect } from '@/interceptors/guestRedirect.js'
router.beforeEach(guestRedirect)

//@TODO - run this only on API requests (And ones that require authentication)?
import { addAuthorizationHeader } from '@/interceptors/addAuthorizationHeader.js'
import { handleUnauthorizedResponse } from '@/interceptors/handleUnauthorizedResponse.js'

axios.interceptors.request.use(addAuthorizationHeader)
axios.interceptors.response.use(
	(response) => {
		return response
	}, handleUnauthorizedResponse
)


fetch(process.env.VUE_APP_API_BASE + 'misc/data').then(res => res.json()).then(data => {

	localStorage.setItem('levels', JSON.stringify(data.levels))
	localStorage.setItem('plans', JSON.stringify(data.plans))
	localStorage.setItem('cities', JSON.stringify(data.cities))
	localStorage.setItem('social', JSON.stringify(data.social))

	/*
    widgetsUtility.setDefaultWidgets(data['widgets'])
    widgetsUtility.setHtmlTemplates(data['html_templates'])
    widgetsUtility.setPdfTemplates(data['pdf_templates'])
	*/

	const app = createApp(App)
	    .use(pinia)
	    .use(router)
	    .use(VueAxios, axios)
	    .mount('#app')

})
