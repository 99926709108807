import { createWebHistory, createRouter } from 'vue-router'

import authRoutes from '@/routes/authRoutes.js'
import discountRoutes from '@/routes/discountRoutes.js'
import cityRoutes from '@/routes/cityRoutes.js'
import userRoutes from '@/routes/userRoutes.js'

var routes = []
routes.push(...authRoutes)
routes.push(...discountRoutes)
routes.push(...cityRoutes)
routes.push(...userRoutes)

routes.push({
	path: '/',
	redirect: '/discounts'
})

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router
