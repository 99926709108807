import { useAuthStore } from '@/stores/auth.js';

export const guestRedirect = function(to, from, next) {

    const publicPages = [
        '/auth/login'
    ]

    let authRequired = true
    for (let link of publicPages) {
        if (to.path.includes(link)) {
            authRequired = false
        }
    }

    const auth = useAuthStore()

    //@TODO - OR token created at > 1 hour
    if (authRequired && (!auth.user || !auth.token)) {
		auth.returnUrl = to.fullPath
        next('/auth/login')
    }
    else {
		next()
	}
}
