<template>
    <div class="form-holder">
        <form method="post" action="" v-on:submit="submitForm">

            <div class="form-group">
                <!--
                <label>Image: *</label><br />
                <div v-if="imageName">
                    <img :src="imageUrl" style="width: auto; height: auto; max-width: 200px; max-height: 200px;" />
                </div>
                <label class="upload-btn-label btn btn-primary" @click="resetFile">
                    Upload image
                    <input type="file" id="image-upload-input" name="image"
                        accept=".png,.jpg,.jpeg,.gif"
                        hidden @change="uploadFile" />
                </label>
                -->
              <label style="display: block;">Square Image (Thumbnail):</label>
              <a class="btn" href="javascript:;" @click="toggleShowSquare">Upload</a><br>

              <my-upload field="square_image"
                         @crop-success="cropSuccess"
                         @crop-upload-success="cropUploadSuccess"
                         @crop-upload-fail="cropUploadFail"
                         v-model="showImageUploadSquare"
                         :width="500"
                         :height="500"
                         lang-type="he"
                         :url="uploadEndpointSquare"
                         :headers="imageUploadHeaders"
                         img-format="png">
              </my-upload>

              <img v-if="imgNameSquare" :src="imgUrlSquare" width="200">
            </div>
            <div class="form-group">
              <label style="display: block;">Wide Image:</label>
              <a class="btn" href="javascript:;" @click="toggleShowWide">Upload</a><br>

              <my-upload field="wide_image"
                         @crop-success="cropSuccess"
                         @crop-upload-success="cropUploadSuccess"
                         @crop-upload-fail="cropUploadFail"
                         v-model="showImageUploadWide"
                         :width="1000"
                         :height="500"
                         lang-type="he"
                         :url="uploadEndpointWide"
                         :headers="imageUploadHeaders"
                         img-format="png">
              </my-upload>

              <img v-if="imgNameWide" :src="imgUrlWide" width="400">
            </div>

            <div class="form-group">
                <label>Name: *</label>
                <input type="text" name="name" v-model="finalData.name" required />
            </div>
            <div class="form-group">
                <label>Address: *</label>
                <input type="text" name="address" v-model="finalData.address" required />
            </div>
            <div class="form-group">
                <label>GeoLocation - Coordinates: *</label><br />
                <input type="number" v-model="geoLocationX" placeholder="X (Longitude)" style="max-width: 200px;" min="-180" max="180" step="any" />
                &nbsp;
                <input type="number" v-model="geoLocationY" placeholder="Y (Latitude)" style="max-width: 200px;" min="-90" max="90" step="any" />
            </div>
            <div class="form-group">
                <label>City: *</label>
                <select name="city" v-model="cityString">
                    <option v-for="city of miscStore.cities" :value="city.code" :key="city.code">{{ city.name }}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Discount: *</label>
                <input type="number" name="discount" v-model="finalData.discount" required />
            </div>
            <div class="form-group">
                <label>Category: *</label>
                <select name="category" v-model="finalData.category">
                    <option v-for="miscLevel of miscStore.levels" :value="miscLevel" :key="miscLevel">{{ miscLevel }}</option>
                </select>
            </div>

            <div class="form-group">
                <input type="checkbox" name="featured" v-model="finalData.featured" /> Featured
            </div>
            <div class="form-group">
                <label>Social links:</label><br />

                <div class="add-social-holder">
                    <select v-model="activeSocialType" style="max-width: 400px;">
                        <option v-for="socialType of miscStore.social" :key="socialType">{{ socialType }}</option>
                    </select>
                    <input type="url" placeholder="Link" v-model="activeSocialLink" style="max-width: 400px;" />
                    <a href="javascript:;" v-on:click="addSocialLink">Add</a>
                </div>

                <hr />

                <div class="social-links-holder">
                    <div v-for="socialObj of finalData.social" :key="socialObj.network + '_' + socialObj.value">
                        <span class="social-network">{{ socialObj.network }}: </span>&nbsp;&nbsp;
                        <span class="social-value">{{ socialObj.value }}</span>&nbsp;&nbsp;
                        <a href="javascript:;" v-on:click="deleteSocial(socialObj.network)">Delete</a>
                    </div>
                </div>
            </div>

            <div class="form-group">
              <label>Email: *</label>
              <input type="email" name="email" v-model="finalData.email" required />
            </div>

            <div class="form-group">
              <label>Phone: *</label>
              <input type="text" name="phone" v-model="finalData.phone" required />
            </div>

            <div class="form-group">
              <label>Company Name: *</label>
              <input type="text" name="companyName" v-model="finalData.companyName" required />
            </div>

            <div class="form-group">
              <label>Company ID: *</label>
              <input type="text" name="companyId" v-model="finalData.companyId" required />
            </div>

            <div class="form-group">
                <button type="submit">{{ submitText || 'Submit' }}</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useMiscStore } from '@/stores/misc.js';
import { useAuthStore } from '@/stores/auth.js';

import myUpload from 'vue-image-crop-upload';

const uploadEndpoint = process.env.VUE_APP_API_BASE + 'admin/discounts/upload'
const publicUploadsUrl = process.env.VUE_APP_API_BASE + '/discounts/image/'

export default {
    name: 'DiscountsForm',
    props: ['formData', 'submitText'],
    emits: ['formSubmitted'],
    components: {
      myUpload
    },
    data: function() {
        return {
            finalData: {},

            imgNameSquare: '',
            imgNameWide: '',

            cityString: '',

            geoLocationX: '',
            geoLocationY: '',

            activeSocialType: '',
            activeSocialLink: '',

            showImageUploadSquare: false,
            showImageUploadWide: false,
            imgDataUrlSquare: '',
            imgDataUrlWide: '',
            imageUploadHeaders: {},
            uploadEndpointSquare: uploadEndpoint + '/square',
            uploadEndpointWide: uploadEndpoint + '/wide'
        }
    },
    computed: {
        imgUrlSquare() {
            return publicUploadsUrl + this.imgNameSquare
        },
        imgUrlWide() {
            return publicUploadsUrl + this.imgNameWide
        },
        ...mapStores(useMiscStore, useAuthStore)
    },
    created() {
        this.finalData = JSON.parse(JSON.stringify(this.formData))

        this.imageUploadHeaders = {
          'Authorization': 'Bearer ' + this.authStore.$state.token
        }

        console.log(this.finalData)

        this.imgNameSquare = this.finalData.squareImage
        this.imgNameWide = this.finalData.coverImage

        const city = this.finalData.city
        if (city && city.code) {
            this.cityString = city.code
        }

        const geoLocation = this.finalData.geoLocation
        if (geoLocation && geoLocation.coordinates && geoLocation.coordinates.length == 2) {
            this.geoLocationX = geoLocation.coordinates[0]
            this.geoLocationY = geoLocation.coordinates[1]
        }
    },
    methods: {
        deleteSocial(type) {
            for (let key in this.finalData.social) {
                const socialObj = this.finalData.social[key]
                if (socialObj.network == type) {
                    this.finalData.social.splice(key, 1)
                    return
                }
            }
        },
        hasSocialType(type) {
            if (!this.finalData.social) {
                return false
            }

            for (let socialObj of this.finalData.social) {
                if (socialObj.network == type) {
                    return true
                }
            }
            return false
        },
        addSocialLink() {
            const type = this.activeSocialType
            const link = this.activeSocialLink

            if (!link.startsWith("https://")) {
                alert('Invalid URL')
                return
            }
            if (this.hasSocialType(type)) {
                alert('This social network is already added!')
                return
            }

            if (!this.finalData['social']) {
                this.finalData['social'] = []
            }
            this.finalData['social'].push({
                'network': type,
                'value': link
            })
        },
        submitForm(e) {
            e.preventDefault()

            //handle images
            if (this.imgNameSquare?.length) {
                this.finalData['squareImage'] = this.imgNameSquare
            }
            if (this.imgNameWide?.length) {
              this.finalData['coverImage'] = this.imgNameWide
            }

            //handle city object
            const myCityCode = this.cityString
            const myCityObj = this.miscStore.getCityByCode(myCityCode)
            this.finalData['city'] = myCityObj

            //handle Geo Coordinates
            const geoLocationObj = {
                coordinates: [this.geoLocationX, this.geoLocationY]
            }
            this.finalData['geoLocation'] = geoLocationObj

            this.$emit('formSubmitted' , this.finalData)
        },
      /*
        resetFile() {
            document.getElementById('image-upload-input').value = '';
        },
        uploadFile(event) {
            const myFile = event.target.files[0];

            var formData = new FormData()
            formData.append("image", myFile)

            this.axios.post(uploadEndpoint, formData)
                .then(response => {
                    if (response.data.url) {
                        this.imageName = response.data.url
                        return
                    }
                    console.log(response)
                    alert('Error occurred')
                }).catch(error => {
                    console.log(error)
                    alert('Error occurred')
                })
        },

       */
      toggleShowSquare() {
        this.showImageUploadSquare = !this.showImageUploadSquare;
      },
      toggleShowWide() {
        this.showImageUploadWide = !this.showImageUploadWide;
      },
      /**
       * crop success
       *
       * [param] imgDataUrl
       * [param] field
       */
      cropSuccess(imgDataUrl, field){
        //console.log('-------- crop success --------');
        //alert(field);
        /*
        if (field === 'square_image') {
          this.imgDataUrlSquare = imgDataUrl;
        }
        else if (field === 'wide_image') {
          this.imgDataUrlWide = imgDataUrl;
        }
         */
      },
      /**
       * upload success
       *
       * [param] jsonData  server api return data, already json encode
       * [param] field
       */
      cropUploadSuccess(jsonData){
        //console.log('-------- upload success --------');
        if (jsonData.field === 'square_image') {
          this.imgNameSquare = jsonData.filename
        }
        else if (jsonData.field === 'wide_image') {
          this.imgNameWide = jsonData.filename
        }
        //console.log(jsonData);
        //console.log('field: ' + field);
      },
      /**
       * upload fail
       *
       * [param] status    server api return error status, like 500
       * [param] field
       */
      cropUploadFail(status, field){
        alert('Error occurred while uploading image!')
        if (field === 'square_image') {
          this.imgDataUrlSquare = ''
        }
        else if (field === 'wide_image') {
          this.imgDataUrlWide = ''
        }
        console.log(status);
      }

    }
}
</script>

<style lang="scss" scoped>
.add-social-holder {
    display: flex;
    flex-flow: row;
    gap: 20px;
    align-items: center;
}
.social-network {
    font-weight: bold;
}
.social-value {
    font-style: italic;
}
.upload-btn-label {
    color: blue !important;
    cursor: pointer;
    text-decoration: underline !important;
}
</style>
