<template>
    <div id="users-index-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">Users</h1>

                <div class="table-header">
                    <div class="table-header__tabs"></div>
                    <div class="table-header__controls">
                        <div class="table-header__controls__search">

                        </div>
                    </div>
                </div>

                <NewDataTable class="discounts-data-table"
                        :dataUrl="dataUrl"
                        :displayId="false"
                        :key="tableRefreshKey"
                        :dataFormatter="formatData"
                        @actionTriggered="actionTriggered">

                    <template v-slot:empty>
                        No records found
                    </template>
                </NewDataTable>

            </section>
        </div>

        <OverlayModal v-if="openModal && activeModal == 'addBusiness'"
                :title="modalTitle"
                submitText="Submit"
                closeText="Close"

                @modalClosed="openModal = false"
                @modalSubmitted="addBusiness">

            <div class="dashboard-view">
                <div class="add-business-form">
                    <div class="form-group">
                        <label>Business ID:</label>
                        <input type="text" v-model="addBusinessId" class="form-control" />
                    </div>
                    <p>Put zero '0' to remove business</p>
                </div>
            </div>
        </OverlayModal>

        <OverlayModal v-if="openModal && activeModal == 'addPlan'"
                :title="modalTitle"
                submitText="Submit"
                closeText="Close"

                @modalClosed="openModal = false"
                @modalSubmitted="addPlan">

            <div class="dashboard-view">
                <div class="add-plan-form">
                    <div class="form-group">
                        <label>Select plan:</label>
                        <select v-model="selectedPlan">
                            <option value="0">Remove plan</option>
                            <option v-for="level of miscStore.$state.levels" :value="level" :key="level">{{ level }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </OverlayModal>
    </div>
</template>

<script>
import NewDataTable from '@/components/global/NewDataTable.vue'
import OverlayModal from '@/components/global/OverlayModal.vue'

const endpoint = process.env.VUE_APP_API_BASE + 'admin/users'

import { mapStores } from 'pinia'
import { useMiscStore } from '@/stores/misc.js';

export default {
    name: 'UsersIndex',
    components: {
        NewDataTable,
        OverlayModal
    },
    data: function() {
        return {
            dataUrl: endpoint,
            tableRefreshKey: null,

            openModal: false,

            activeModal: '',
            modalTitle: '',
            activeUserId: null,

            addBusinessId: '',
            selectedPlan: ''
        }
    },
    computed: {
        ...mapStores(useMiscStore)
    },
    methods: {
        formatData(data) {
            const newData = []
            for (let obj of data) {
                const newObj = {}
                for (let key in obj) {
                    const ignore = ['__v']
                    if (ignore.includes(key)) {
                        continue
                    }
                    else {
                        newObj[key] = {
                            'label': key,
                            'value': obj[key],
                            'display_type': 'text'
                        }
                    }
                }


                const actions = {
                    'add_business': `<i class="add-business-action" title="Add Business">Add Business</i>`,
                    'add_plan': `<i class="add-plan-action" title="Add Plan">Add Plan</i>`
                }
                newObj['actions'] = {
                    'label': '',
                    'value': actions,
                    'display_type': 'actions'
                }


                //const newObjOrdered = Object.assign({'image': ''}, newObj)
                newData.push(newObj)
            }
            return newData
        },
        actionTriggered(actionKey, identifier) {
            if (!identifier) {
                alert('Error occurred #89')
            }
            else if (actionKey == 'add_business') {
                this.activeUserId = identifier
                this.addBusinessId = ''
                this.activeModal = 'addBusiness'

                this.openModal = true
            }
            else if (actionKey == 'add_plan') {
                this.activeUserId = identifier
                this.activeModal = 'addPlan'
                this.openModal = true
            }
        },
        addBusiness() {
            const userId = this.activeUserId
            const businessId = this.addBusinessId

            if (!businessId) {
                alert('Invalid business ID')
                return
            }

            this.axios.post(endpoint + '/' + userId + '/add-business', { businessId: businessId })
                .then(response => {
                    if (response.data.result == 'success') {
                        alert('success')
                        this.tableRefreshKey = Date.now()
                    }
                    else {
                        alert(response.data.message)
                    }
                })
                .catch(e => {
                    alert(e.message)
                    console.log(e)
                })
        },
        addPlan() {
            const userId = this.activeUserId
            const level = this.selectedPlan

            this.axios.post(endpoint + '/' + userId + '/add-plan', { level: level })
                .then(response => {
                    if (response.data.result == 'success') {
                        alert('success')
                        this.tableRefreshKey = Date.now()
                    }
                    else {
                        alert(response.data.message)
                    }
                })
                .catch(e => {
                    alert(e.message)
                    console.log(e)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
