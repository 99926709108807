<template>
    <div id="discounts-edit-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">Edit Business</h1>

                <template v-if="discountId !== null && loaded">
                    <DiscountsForm :formData="formData" submitText="Update" @formSubmitted="formSubmitted" />
                </template>

            </section>
        </div>
    </div>
</template>

<script>
import DiscountsForm from '@/components/forms/DiscountsForm.vue'

const endpoint = process.env.VUE_APP_API_BASE + 'admin/discounts'

export default {
    name: 'DiscountsEdit',
    components: {
        DiscountsForm
    },
    data: function() {
        return {
            discountId: null,
            loaded: false,
            formData: {
                name: '',
                address: '',
                discount: 0,
                category: '',
                city: {
                    name: '',
                    country_code: 'ro',
                    code: ''
                },
                featured: false
            }
        }
    },
    created() {
        this.discountId = this.$route.params.id
        this.axios.get(endpoint + '/' + this.discountId)
            .then(response => {
                this.formData = response.data
                this.loaded = true
            }).catch(error => {
                alert('Error occurred')
                console.log(error)
            })
    },
    methods: {
        formSubmitted(finalData) {
            this.axios.post(endpoint + '/' + this.discountId + '/update', finalData)
                .then(response => {
                    if (response.data.result == 'success') {
                        alert('success')
                        this.$router.push('/discounts')
                    }
                    else {
                        alert(response.data.message)
                    }
                }).catch(error => {
                    alert('Error occurred')
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
