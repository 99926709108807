import { defineStore } from 'pinia'
import axios from 'axios'

export const useMiscStore = defineStore({
    id: 'misc',
    state: () => ({
        // initialize state from local storage to enable user to stay logged in
        levels: JSON.parse(localStorage.getItem('levels')),
        plans: JSON.parse(localStorage.getItem('plans')),
        cities: JSON.parse(localStorage.getItem('cities')),
        social: JSON.parse(localStorage.getItem('social'))
    }),
    actions: {
        getCityByCode(code) {
            for (let city of this.$state.cities) {
                if (city.code == code) {
                    return city
                }
            }
            return null
        },
        getCitiesDictionary() {
            const myDictionary = {}
            for (let key in this.$state.cities) {
                const myCity = this.$state.cities[key]
                myDictionary[myCity.code] = myCity.name
            }
            //console.log(myDictionary)
            return myDictionary
        },
        getLevelsDictionary() {
            const myDictionary = {}
            for (let level of this.$state.levels) {
                myDictionary[level] = level
            }
            //console.log(myDictionary)
            return myDictionary
        },
        refreshData() {
            fetch(process.env.VUE_APP_API_BASE + 'misc/data').then(res => res.json()).then(data => {
            	localStorage.setItem('cities', JSON.stringify(data.cities))
                this.$state.cities = data.cities
            })
        }
    }
})
