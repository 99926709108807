<template>
    <div id="cities-index-page" class="dashboard-view">
        <div class="container">
            <section class="content">
                <h1 class="page-title">Cities</h1>

                <div class="table-header">
                    <div class="table-header__tabs"></div>
                    <div class="table-header__controls">
                        <div class="table-header__controls__search">

                        </div>
                        <a href="/cities/create" class="table-header__controls__btn">Create</a>
                    </div>
                </div>

                <NewDataTable class="cities-data-table"
                        :dataUrl="dataUrl"
                        :displayId="false"
                        :key="tableRefreshKey"
                        :dataFormatter="formatData"
                        @actionTriggered="actionTriggered">

                    <template v-slot:empty>
                        No records found
                    </template>
                </NewDataTable>

            </section>
        </div>
    </div>
</template>

<script>
import NewDataTable from '@/components/global/NewDataTable.vue'

const endpoint = process.env.VUE_APP_API_BASE + 'admin/cities'

export default {
    name: 'CitiesIndex',
    components: {
        NewDataTable
    },
    data: function() {
        return {
            dataUrl: endpoint,
            tableRefreshKey: null
        }
    },
    methods: {
        formatData(data) {
            const newData = []
            for (let obj of data) {
                const newObj = {}
                for (let key in obj) {
                    const ignore = ['__v']
                    if (ignore.includes(key)) {
                        continue
                    }
                    newObj[key] = {
                        'label': key,
                        'value': obj[key],
                        'display_type': 'text'
                    }
                }

                const actions = {
                    'edit': `<i class="edit-action" title="Edit">Edit</i>`,
                    'delete': `<i class="delete-action" title="Delete" style="color: red;">Delete</i>`
                }
                newObj['actions'] = {
                    'label': '',
                    'value': actions,
                    'display_type': 'actions'
                }

                //put image first

                newData.push(newObj)
            }
            return newData
        },
        actionTriggered(actionKey, identifier) {
            if (!identifier) {
                alert('Error occurred #89')
            }
            else if (actionKey == 'edit') {
                this.$router.push('/cities/' + identifier + '/edit')
            }
            else if (actionKey == 'delete') {
                this.deleteForm(identifier)
            }
        },
        deleteForm(identifier) {
            if (confirm('Are you sure you want to delete this entity?')) {
                this.axios.delete(endpoint + '/' + identifier)
                    .then(response => {
                        if (response.data.result == 'success') {
                            alert('success')
                            this.tableRefreshKey = Date.now()
                        }
                        else {
                            alert(response.data.message)
                        }
                    })
                    .catch(e => {
                        alert(e.message)
                        console.log(e)
                    })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
